import React from 'react'
import './style.scss'
export const SidebarContent = ({ isVisible = false, children }) => {

    const st = isVisible ? 'show' : ''

    return (
        <div className={`sidebar-content ${st}`}>
            <div className='content'>
                {children}
            </div>
        </div>

    )
}
