import winIcon from '../assets/images/dw_win.png'
import linuxIcon from '../assets/images/dw_linux.png'
import macIcon from '../assets/images/dw_mac.png'

const navInfo = window.navigator.appVersion.toLowerCase();

let info = { icon: '', urlDownload: '#' };

export const getInfo = () => {
    if (navInfo.indexOf('win') !== -1) {
        info = downloadInfo.win
    }
    else if (navInfo.indexOf('linux') !== -1) {

        info = downloadInfo.linux
    }
    else if (navInfo.indexOf('mac') !== -1) {

        info = downloadInfo.mac
    }
    return info
}
export const downloadInfo = {
    win: { icon: winIcon, urlDownload: process.env.REACT_APP_WINDOWS_URL ?? '#', ext: 'exe', size: 79042888 },
    linux: { icon: linuxIcon, urlDownload: process.env.REACT_APP_LINUX_URL ?? '#', ext: 'snap', size: 65613824 },
    mac: { icon: macIcon, urlDownload: process.env.REACT_APP_MAC_URL ?? '#', ext: 'dmg', size: 95870573 }
}


