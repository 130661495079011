
import './assets/css/App.scss';
import Slider from "react-slick";
import Link from './Link';
import { useEffect, useState } from 'react'
import Services from './components/Services';
import { getInfo } from './utils/detectOS'
import { settings } from './utils/configSlick'
// import axios from 'axios'
// import bitpointer from './assets/images/bitpointer.svg'
import logo from './assets/images/logo.png'
import ImageCard from './components/ImageCard';
import { downloadAndRename } from './utils/download';

function App() {

  const productName = 'FITS Liberator'

  const { icon, urlDownload, ext, size } = getInfo()

  // const productName = 'NOIRLab/IPAC/ESA/STScI/CfA FITS Liberator '
  // const lightName = 'FITS Liberator '

  const [showServices, setShowServices] = useState(false)
  const [loading, setLoading] = useState(false)
  const [percent, setPercent] = useState(0)


  const onClickPayment = () => {
    setShowServices(!showServices)
  }
  const click = e => {
    if (document.querySelector('.sidebar-content').contains(e.target)) {
      // Clicked in box      

    } else {
      // Clicked outside the box
      setShowServices(false)
    }
  }
  useEffect(() => {

    window.addEventListener('click', click, true);
    return () => {
      window.removeEventListener('click', click);
    }
  })

  // const onUploadProgress = progressEvent => {
  //   let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
  //   console.log(percentCompleted)
  //   setPercent(percentCompleted)
  //   // do whatever you like with the percentage complete
  //   // maybe dispatch an action that will update a progress bar or something
  // }

  // const downloadAs = () => {
  //   setLoading(true)
  //   axios.get(urlDownload, {
  //     headers: {
  //       "Content-Type": "application/octet-stream"
  //     },
  //     withCredentials: false,
  //     responseType: "blob",
  //     onUploadProgress
  //   })
  //     .then(response => {
  //       const a = document.createElement("a");
  //       const url = window.URL.createObjectURL(response.data);
  //       a.href = url;
  //       a.download = `${productName}_Setup.${ext}`;
  //       a.click();
  //     })
  //     .catch(err => {
  //       console.log("error", err);
  //     })
  //     .finally(() => setLoading(false))
  // };

  async function main() {
    setPercent(0)
    setLoading(true)
    console.log('downloading...');
    const response = await fetch(urlDownload);
    const contentLength = response.headers.get('content-length') ?? size
    const total = parseInt(contentLength, 10);
    let loaded = -10;

    const res = new Response(new ReadableStream({
      async start(controller) {
        const reader = response.body.getReader();
        for (; ;) {
          const { done, value } = await reader.read();
          if (done) break;
          loaded += value.byteLength;
          // progress({ loaded, total })
          const percentComplete = Math.round((loaded / total) * 100);
          setPercent(percentComplete)
          controller.enqueue(value);
        }
        controller.close();
      },
    }));
    const blob = await res.blob()
    const filename = `${productName} Setup.${ext}`
    console.log('download completed')
    downloadAndRename({ blob, filename })
    setLoading(false)
  }
  return (
    <div className="App">
      <header className="header">
      </header>
      <section>
        <h2 style={{ paddingLeft: '2em', display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt='FL4' width='50' style={{ marginRight: 10 }} /> {productName}
        </h2>
        <div className="list-proj">
          <div id="hero" className="Hero" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1571171637578-41bc2dd41cd2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80) cover' }}>
            <div className="content">
              <h2 >FL4</h2>
              <p>The release of version 4 of the popular NOIRLab/IPAC/ESA/STScI/CfA FITS Liberator image processing software has meant that it is even easier and faster to create colour images using raw observations from a range of telescopes, including the NASA/ESA Hubble Space Telescope, NASA’s Spitzer Space Telescope, ESO’s Very Large Telescope and ESA’s XMM-Newton Telescope.</p>
              <div className="button-wrapper">
                <Link primary text="Download" icon={icon} onClick={() => main()} loading={loading} percent={percent} />
                <Link text="More +" onClick={onClickPayment} />
              </div>
            </div>
            <div className="overlay"></div>
          </div>
          <div className="content-slider">
            <Slider {...settings}>
              {[1, 2, 3, 4, 5, 6, 7].map(x => {
                const url = `https://bitpointer.co/fl/image${x}.png`
                return <ImageCard url={url} key={x} />
              })}
            </Slider>
          </div>

          <div className="sponsor-team">
            <h2> Sponsors </h2>

            <div className="sponsors-grid " >
              <a className="" href='https://noirlab.edu' target="_blank" rel="noreferrer">
                <img src="https://noirlab.edu/public/static/images/logos/noirlab-nsf.84fba631858b.svg" alt="" width="150px" />
              </a>
              <a href='https://www.ipac.caltech.edu' target="_blank" rel="noreferrer">
                <img alt="IPAC" title="Infrared Processing and Analysis Center" src="https://www.ipac.caltech.edu/assets/main_ipac_logo-ea4b4e2692a00c68ff2f446d8d468f85.png" width="60" />
              </a>
              <a href='https://www.esa.int' target="_blank" rel="noreferrer">
                <img src="https://www.esa.int/extension/pillars/design/pillars/images/ESA_Logo.svg" alt="ESA" title="European Space Agency" width="100px" />
              </a>
              <a href='https://www.stsci.edu' target="_blank" rel="noreferrer">
                <img title="Space Telescope Science Institute" alt="STScI" src="https://www.stsci.edu/files/live/sites/www/files/home/_images/footer/stsci_pri_combo_mark_white_bkgd.png?t=tn2400" width="90" />
              </a>
              <a className="" href='https://www.cfa.harvard.edu' target="_blank" rel="noreferrer">
                <img title="CENTER FOR ASTROPHYSICS HARVARD & SMITHSONIAN" alt="HARVARD" src="https://www.cfa.harvard.edu/sites/all/themes/cfa_theme/images/cfa_theme_logo_black.png" width="150" />
              </a>


            </div>
            {/* <div>development team</div> */}
          </div>
          <canvas id="webgl" style={{ maxHeight: '70%', maxWidth: '100%' }}></canvas>
        </div>
      </section>

      <footer>
        <ul>
          <li><a href={`https://en.wikipedia.org/wiki/FITS_Liberator`} target="_blank" rel="noreferrer" ><b>Wikipedia</b>  </a> </li>
          <li><a href={`https://esahubble.org/projects/fits_liberator/`} target="_blank" rel="noreferrer" ><b>ESAHubble</b>  </a> </li>
          <li><a href={`https://noirlab.edu/fitsliberator`} target="_blank" rel="noreferrer" ><b>NOIRLab </b>  </a></li>
        </ul>
      </footer>

      <Services isVisible={showServices} />
      {/* <Payments isVisible={showPayments} /> */}
    </div >
  );
}

export default App;
