import React, { Component } from 'react'

class ImageCard extends Component {



    imgRef = React.createRef()
    state = {
        span: 0,
        loading: true
    }

    // componentDidMount = () => this.imgRef.current.addEventListener('load', this.setSpan)

    setSpan = () => {
        const height = this.imgRef.current.clientHeight

        console.log(height)
        const span = Math.ceil(height)

        this.setState({ span, loading: false })
    }
    render() {
        const { url, loading } = this.props

        return (
            <div className="shadow" >
                {loading && <p style={{ color: '#fff' }}>Loading...</p>}
                <img src={url} alt="" width="100%" />
            </div>
        )
    }
}
export default ImageCard