import React from 'react'
import { SidebarContent } from './modal'
import logoOsd from '../assets/images/openseadragon-logo_n.png'
import nasa from '../assets/images/nasa.png'
import libvips from '../assets/images/libvips.jpg'
import d3Lib from '../assets/images/d3.svg'
import { downloadInfo } from '../utils/detectOS'
export default function Services({ isVisible }) {


    return (
        <SidebarContent isVisible={isVisible}>
            <h3>About FL4 </h3>
            <div className="services">
                <div className="service-grid">
                    <a className="service" href="/fl4" target="_blank" rel="noreferrer">
                        <span className="iconify" data-icon="mdi-transit-detour" data-inline="false"></span>
                        <div>
                            <b>Take the Tour </b>
                            <div>
                                FL4 Guide
                        </div>
                        </div>
                    </a>
                    <a className="service" href="https://www.youtube.com/watch?v=3Dmij61Y0qQ" target="_blank" rel="noreferrer">
                        <span className="iconify" data-icon="mdi-youtube" data-inline="false"></span>
                        <div>
                            <b>Watch FL4 in action</b>
                        </div>
                    </a>
                </div>
            </div>
            <h3 className="space">Download </h3>
            <div className="services">
                <div className="service">
                    <ul className="download-grid">
                        <li>
                            <a href={downloadInfo.win.urlDownload} rel="noreferrer">
                                <img src={downloadInfo.win.icon} alt="Windows Download" title="Windows Download" />
                                <p>Windows</p>
                            </a>
                        </li>
                        <li>
                            <a href={downloadInfo.linux.urlDownload} rel="noreferrer">
                                <img src={downloadInfo.linux.icon} alt="Linux Download" title="Linux Download" />
                                <p>Linux</p>
                            </a>
                        </li>
                        <li>
                            <a href={downloadInfo.mac.urlDownload} rel="noreferrer">
                                <img src={downloadInfo.mac.icon} alt="MacOSX Download" title="MacOSX Download" />
                                <p>MacOSX</p>
                            </a>
                        </li>
                    </ul>

                </div>
                <div className="service">
                    <span className="iconify" data-icon="mdi-semantic-web" data-inline="false"></span>
                    <div>
                        <h3>Technologies</h3>
                        <br />
                        <div className="techlogos">
                            <div>
                                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K"
                                    alt="ReactJS" title="React JS" width="40px" /> <span>React</span>
                            </div>
                            <div>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Electron_Software_Framework_Logo.svg/100px-Electron_Software_Framework_Logo.svg.png"
                                    alt="Electron" title="Electron JS" width="40px" /><span>Electron</span>
                            </div>
                            <div>
                                <img src={logoOsd} alt="OpenSeaDragon" title="OpenSeaDragon" height="40px" />
                            </div>
                            <div>
                                <img src={nasa} alt="NASA’s CFITSIO library" title="NASA’s CFITSIO library" height="40px" /> <span>NASA’s CFITSIO</span>
                            </div>

                            <div>
                                <img src={libvips} alt="Libvips" title="Libvips" height="40px" />
                            </div>
                            <div>
                                <img src={d3Lib} alt="D3 JS" title="D3 JS" height="40px" /><span>D3.js</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SidebarContent>
    )
}
