import React from 'react'
import Loading from './components/Loading';
import './link.scss'

const Link = ({ primary, text, icon, link = "#", loading = false, percent, ...rest }) => {

    return (
        <a href={link} className="Button" data-primary={primary} {...rest}>
            <Loading isVisible={loading} percentage={percent} >
                <img src={icon} alt='OS Icon' width='40' />
            </Loading>
            {icon && !loading && <img src={icon} alt='OS Icon' width='40' />} <span>{text}</span>
        </a>
    );

}

export default Link