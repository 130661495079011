import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import '../assets/css/loading.scss'

const Loading = ({ isVisible, percentage, children }) => {

    if (!isVisible) return null

    return (

        <CircularProgressbarWithChildren value={percentage} text={`${percentage}%`}
            styles={{
                // Customize the root svg element
                root: {
                    width: '37px',
                    position: 'relative'
                },
                // Customize the path, i.e. the "completed progress"
                // path: {
                //     // Path color
                //     stroke: `rgba(62, 152, 199, ${percentage / 100})`,
                //     // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                //     strokeLinecap: 'butt',
                //     // Customize transition animation
                //     transition: 'stroke-dashoffset 0.5s ease 0s',
                //     // Rotate the path
                //     transform: 'rotate(0.25turn)',
                //     transformOrigin: 'center center',
                //   },
                  // Customize the circle behind the path, i.e. the "total progress"
                  trail: {
                    // Trail color
                    stroke: '#d6d6d6',
                    // stroke: '#',
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',
                    // Rotate the trail
                    transform: 'rotate(0.25turn)',
                    transformOrigin: 'center center',
                  },
                  // Customize the text
                  text: {
                    // Text color
                    fill: 'white',
                    // Text size
                    fontSize: '25px',
                  },
                  // Customize background - only used when the `background` prop is true
                  background: {
                    fill: '#3e98c7',
                  },
            }}
        >
                 {/* <svg width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg" stroke="#fff" className="iconify">
            <g fill="none" fill-rule="evenodd" transform="translate(1 1)" stroke-width="2">
                <circle cx="22" cy="22" r="6" stroke-opacity="0">
                    <animate attributeName="r"
                        begin="1.5s" dur="3s"
                        values="6;22"
                        calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="stroke-opacity"
                        begin="1.5s" dur="3s"
                        values="1;0" calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="stroke-width"
                        begin="1.5s" dur="3s"
                        values="2;0" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="22" cy="22" r="6" stroke-opacity="0">
                    <animate attributeName="r"
                        begin="3s" dur="3s"
                        values="6;22"
                        calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="stroke-opacity"
                        begin="3s" dur="3s"
                        values="1;0" calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="stroke-width"
                        begin="3s" dur="3s"
                        values="2;0" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="22" cy="22" r="8">
                    <animate attributeName="r"
                        begin="0s" dur="1.5s"
                        values="6;1;2;3;4;5;6"
                        calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
            </g>
        </svg> */}
        </CircularProgressbarWithChildren>
    )
}
// const Loading = ({ isVisible }) => {

//     if (!isVisible) return null

//     return (

        // <svg width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg" stroke="#fff" className="iconify">
        //     <g fill="none" fill-rule="evenodd" transform="translate(1 1)" stroke-width="2">
        //         <circle cx="22" cy="22" r="6" stroke-opacity="0">
        //             <animate attributeName="r"
        //                 begin="1.5s" dur="3s"
        //                 values="6;22"
        //                 calcMode="linear"
        //                 repeatCount="indefinite" />
        //             <animate attributeName="stroke-opacity"
        //                 begin="1.5s" dur="3s"
        //                 values="1;0" calcMode="linear"
        //                 repeatCount="indefinite" />
        //             <animate attributeName="stroke-width"
        //                 begin="1.5s" dur="3s"
        //                 values="2;0" calcMode="linear"
        //                 repeatCount="indefinite" />
        //         </circle>
        //         <circle cx="22" cy="22" r="6" stroke-opacity="0">
        //             <animate attributeName="r"
        //                 begin="3s" dur="3s"
        //                 values="6;22"
        //                 calcMode="linear"
        //                 repeatCount="indefinite" />
        //             <animate attributeName="stroke-opacity"
        //                 begin="3s" dur="3s"
        //                 values="1;0" calcMode="linear"
        //                 repeatCount="indefinite" />
        //             <animate attributeName="stroke-width"
        //                 begin="3s" dur="3s"
        //                 values="2;0" calcMode="linear"
        //                 repeatCount="indefinite" />
        //         </circle>
        //         <circle cx="22" cy="22" r="8">
        //             <animate attributeName="r"
        //                 begin="0s" dur="1.5s"
        //                 values="6;1;2;3;4;5;6"
        //                 calcMode="linear"
        //                 repeatCount="indefinite" />
        //         </circle>
        //     </g>
        // </svg>
//     )
// }

export default Loading